import ComplianceReportsPage from '../components/ComplianceReports'
import React from 'react'
import Pagewrapper from '../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <ComplianceReportsPage />
        </Pagewrapper>
    )
}

