import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { isDesktop } from 'react-device-detect'
import { useAuth } from '../../hooks/useAuth'
import { ComplianceReportsTypes } from '../../lib/enums'
import UseTaxYears from './useTaxYears'

export default function LLUserComplianceReports() {
    const { session } = useAuth('')

    async function getUser () {
        return session.user
    }

    useEffect(()=> {
        if (!isDesktop) {
            Modal.warning({
                title: 'Warning: small screen size!',
                content: 'This part of the application is meant only for PC.',
            })
        }
    }, [])

    return <UseTaxYears contentType={ComplianceReportsTypes.LLUser} getUserData={getUser} />
}
